import { Roles } from "models";

const getColorChipByActivityType = (activityType: string): "primary" | "warning" | "secondary" | "success" | "error" => {
  switch (activityType) {
    case "Denied":
      return "error";
    case "Approved":
      return "success";
    case "Create":
      return "success";
    case "Pending":
      return "secondary";
    case "Invite":
      return "warning";
    default:
      return "primary";
  }
};

const getColorChipByRole = (role: string): "admin" | "multiOrganization" | "organization" | "location" | undefined => {
  switch (role) {
    case Roles.superAdmin:
      return "admin";
    case Roles.multiOrgManager:
      return "multiOrganization";
    case Roles.multiOrgViewer:
      return "multiOrganization";
    case Roles.organizationManager:
      return "organization";
    case Roles.organizationViewer:
      return "organization";
    case Roles.locationManager:
      return "location";
    case Roles.locationViewer:
      return "location";
    default:
      return undefined;
  }
};

export { getColorChipByActivityType, getColorChipByRole };
