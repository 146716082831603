/* eslint-disable max-len */
const resources = {
  en: {
    translation: {
      "ALL-REQUIRED.INPUT": "Required input",
      "SELECT-ORG-INPUT": "Select an organisation",
      "NEW-PASSWORD-VALIDATION": "Must be 8 characters at least",
      "CONFRIM-PASSWORD-VALIDATION": "Password doesn't match",
      "INVALID-EMAIL": "Invalid email address",
      "INVALID-PHONE": "Phone must be an international number: Eg. +11234567890",
      "INVALID-WEBSITE": "Invalid website",
      "SUCCESS-RESET-PASSWORD": "Password successfully reset. You can now log in.",
      "SUCCESS-RESET-EMAIL-SENT": "Email sent successfully",
      "NEED-SBD-EMAIL": "It must be an @sbdinc.com email",
      "NEED-SBD-EMAIL-ADMIN": "This role must have an SBD account",

      //**Titles */
      "GLOBAL-TITLE-ORGANIZATIONS": "Organizations",
      "GLOBAL-TITLE-ALL-ORGANIZATIONS": "All Organizations",
      "GLOBAL-TITLE-LOCATIONS": "Locations",
      "GLOBAL-TITLE-ALL-LOCATIONS": "All Locations",
      "GLOBAL-TITLE-LOCATION": "Location",
      "GLOBAL-TITLE-USERS": "Users",
      "GLOBAL-TITLE-ALL-USERS": "All Users",
      "GLOBAL-TITLE-USERS-REQUEST": "Requests",
      "GLOBAL-TITLE-USERS-INVITATION": "New User",
      "GLOBAL-TITLE-ROLE_RELATION": "Role Relation",
      "GLOBAL-TITLE-ORGNAIZATION": "Organization",
      "GLOBAL-TITLE-APPLICATIONS": "Applications",
      "GLOBAL-TITLE-NEW-ORGANIZATION": "New Organization",
      "GLOBAL-TITLE-NEW-LOCATION": "Location’s Form",
      "GLOBAL-TITLE-AUDIT-LOG": "Audit Log",
      "GLOBAL-TITLE-API-TOKEN": "API Tokens",
      "GLOBAL-TITLE-BUSINESS-INFO": "Bussiness Information",
      "GLOBAL-TITLE-AUDIT-LOG-DETAILS": "Audit Log Information",
      "GLOBAL-IMPORT": "Import",
      "GLOBAL-EXPORT": "Export",
      "GLOBAL-IMPORT-DETAIL": "Import Overview",
      "GLOBAL-TITLE-ORG-LANDING": "Organization Landing",
      "GLOBAL-SELECT-ADDESS-MAP": "Select this location",
      "GLOBAL-GRID-VIEW": "Grid View",
      "GLOBAL-LIST-VIEW": "List View",
      "GLOBAL-MAP-VIEW": "Map View",
      "GLOBAL-BULK-ACTIONS-EDIT": "Edit",
      "GLOBAL-BULK-ACTIONS-SHOW": "Show",
      "GLOBAL-BULK-ACTIONS-DELETE": "Delete",
      "GLOBAL-BULK-ACTIONS-SUSPEND": "Suspend",
      "GLOBAL-BULK-ACTIONS-ACTIVE": "Activate",

      "FILE-TITLE": "Drop files here or click to upload.",
      "FILE-ALLOWED": "Allowed *.jpg, *.png. Max size 3 MB.",
      "FILE-ALLOWED-CSV": "Allowed *.csv, max size 3 MB.",
      //**API SUCCESS */
      "ALERT-SUCCESS-CREATE": "Successfully created",
      "ALERT-SUCCESS-UPDATE": "Successfully updated",
      "ALERT-SUCCESS-PRIMARY-CONTACT": "Primary Contact successfully updated",
      "ALERT-SUCCESS-SBD-CONNECT": "SBD Connect successfully updated",
      "ALERT-SUCCESS-REQUEST": "Request successfully created",
      "ALERT-SUCCESS-ACCEPT": "Success",
      "ALERT-SUCCESS-DELETE-ACTION": "Successfully deleted",
      "ALERT-SUCCESS-DELETE": "Token successfully deleted.",
      "ALERT-SUCCESS-CLAIM": "Locations successfully claimed.",
      "ALERT-SUCCESS-IMPORT": "File successfully imported.",
      "ALERT-SUCCESS-HQ-UPDATE": "Hq successfully updated.",
      //**API ERRORS */
      "ALERT-GLOBAL-ERROR-GET": "Error getting data",
      "ALERT-ERROR-CREATE": "Error on the creation",
      "ALERT-ERROR-UPDATE": "Error on the update",
      "ALERT-ERROR-REQUEST": "Error sending the request",
      "ALERT-ERROR-ACCEPT": "Error accepting invitation",
      "ALERT-ERROR-DELETE": "Error deleting item",
      "ALERT-ERROR-UPDATE-STATUS": "Error updating status",
      "ALERT-ERROR-CLAIM": "Error claiming a location",
      "ALERT-ERROR-ON-IMPORTS": "Some values in the file are not valid. Please fix them and try again.",
      "ALERT-ERROR-GET-TOKEN-PING-ID": "Something went wrong. please login again. ",
      "ALERT-ERROR-ON-EXPORT": "Something went wrong during the export process. Please try again later.",
      "ALERT-ERROR-MANDATORY-FIELDS": "Mandatory fields not filled in",
      "ALERT-ERROR-UPDATE-PRIMARY-CONTACT-USER": "You can't update a primary contact user",
      "ALERT-ERROR-UPDATE-OTHER-LOC-USER": "You cannot update users who are not associated with your location",
      "ALERT-ERROR-SELECT-ORG": "Please select an organization",
      //** API INFORMATION */
      "ALERT-INFO-REQUEST-REVIEWED": "Request already reviewed",
      "ALERT-INFO-INVALID-USER-ACCESS": "Resourse not found",
      "ALERT-INFO-INVALID-LOCATION": "Resourse not found",
      "ALERT-INFO-NO-ERRORS-ON-IMPORT-LOCATION": "locations will be imported.",
      "ALERT-INFO-IMPORT-NOT-CLOSE": "Please no refresh or close application",

      "GLOBAL-TABLE-TOTAL": "Total",
      "GLOBAL-SELECTED": "Selected",
      "GLOBAL-GO-BACK-MODAL-TITLE": "Are you sure you want to go back?",
      "GLOBAL-GO-BACK-MODAL-DESCRIPTION": "You have not made any changes to this form.",
      //** WARNING */
      "WARNING-ALERT": "This value is inconsistent with Google Maps.",
      "WARNING-ALERT-LOCATION": "This location has inconsistencies with Google Maps",

      "WARNING-ALERT-ERROR": "This location is incomplete.",

      //**Inputs */
      "INPUT-NEW-PASSWORD": "New Password",
      "INPUT-CONFIRM-PASSWORD": "Confirm Password",
      "INPUT-EMAIL": "Email",
      "INPUT-FIRST-NAME": "First Name",
      "INPUT-NAME": "Name",
      "INPUT-ORG-NAME": "Organization name",
      "INPUT-HQ-NAME": "Location's Name",
      "INPUT-LOCATION-NAME": "Location's Name",
      "INPUT-STATUS": "Status",
      "INPUT-URL": "URL",
      "INPUT-LAST-NAME": "Last Name",
      "INPUT-PHONE": "Phone",
      "INPUT-PHONE-NUMBER": "Phone Number",
      "INPUT-SBD-EMAIL": "SBD Point of Contact",
      "INPUT-DESCRIPTION": "Description",
      "INPUT-LOGO": "Logo",
      "INPUT-FRECUENCY": "Frecuency",
      "DROPDOWN-ORGANIZATION": "Organization Name",
      "INPUT-ACTIVE": "Active",
      "INPUT-INACTIVE": "Inactive",
      "INPUT-VISIBILITY": "Visibility",
      "INPUT-PURPUSE": "Purpose",
      "INPUT-REGION": "Region",
      "INPUT-MARKET": "Market",
      "INPUT-WEBSITE": "URL",
      "INPUT-FAX": "Fax",
      "INPUT-ADDRESS": "Address",
      "INPUT-ADDRESS-1": "Address 1",
      "INPUT-ADDRESS-2": "Address 2",
      "INPUT-CITY": "City",
      "INPUT-POSTAL-CODE": "Postal Code",
      "INPUT-COORDINATES": "Coordinates",
      "INPUT-LATITUDE": "Laditude",
      "INPUT-LONGITUDE": "Longitude",
      "INPUT-BRAND": "Applicable brands",
      "INPUT-MARKET-COUNTRY": "Market/Country",
      "INPUT-CATEGORY-PRODUCT": "Categories or Key Products",
      "INPUT-CATEGORY-SERVICED": "CATEGORIES SERVICED",
      "INPUT-APPLICATION": "Application",
      "INPUT-PURPOSE": "Purposes",
      "INPUT-CODE": "Code",
      "INPUT-ACCOUNT-NUMBER": "Account Number",
      "INPUT-RESPONSIBLE-EMAIL": "Responsible email",
      "INPUT-DELIVERY-ACCOUNT-NUMBER": "Account Number Delivery",
      "INPUT-VENDOR-NAME": "Vendor name",
      "INPUT-LOCATION-NUMBER": "Location Number",
      "INPUT-COUNTRY": "Country",
      "INPUT-STATE": "State",
      "INPUT-LANGUAGE": "Language",
      "INPUT-CATEGORIES": "Categories",
      "INPUT-PREMIUM": "Premium",
      "INPUT-FACTORY": "Factory",
      "INPUT-AMOUNT-OF-EMPLOYEES": "Amount of Employees",
      "INPUT-FACILITY-SQUARE": "Area square footage",
      "INPUT-KEY-PRODUCTS": "Key products",
      "INPUT-BUSINESS": "Business",
      "FILE-MAIN": "Main image",
      "FILE-ADD-MORE": "Add more",
      "INPUT-DISPLAY-ORDER": "Display order",
      "INPUT-ROLE": "Role",
      "INPUT-ACTOR": "Actor",
      "INPUT-ACTIVITY-TYPE": "Activity type",
      "INPUT-DATE": "Date",
      "INPUT-AFTER-DATE": "After date",
      "INPUT-BEFORE-DATE": "Before date",
      "INPUT-TIME": "Time",
      "INPUT-RESOURCE": "Resource",
      "INPUT-ACTIVITY": "Activity",
      "INPUT-API-TOKEN": "API Token",
      "INPUT-TITLE": "Designation",
      "INPUT-YEAR-FOUNDED": "Year founded",
      "INPUT-SUSTAINABLE": "Sustainable",
      "INPUT-SEARCH-MAP": "Keywords",
      "INPUT-ORGANIZATION": "Organization",
      "INPUT-LOCATION": "Location",
      "INPUT-ONLINE-ONLY": "Online Only",
      "INPUT-HEADQUARTER": "Headquarter",

      //** options */
      "FRECUENCY-1": "Weekly",
      "FRECUENCY-2": "Monthly",

      //**Buttons */
      "BUTTON-SAVE": "SAVE",
      "BUTTON-SEND": "SEND",
      "BUTTON-SELECT": "SELECT",
      "BUTTON-SEARCH": "SEARCH",
      "BUTTON-RESET": "RESET",
      "BUTTON-RESEND": "RESEND",
      "BUTTON-BACK-LOGIN": "Back to login",
      "BUTTON-PREVIOUS": "PREVIOUS",
      "BUTTON-NEXT": "NEXT",
      "BUTTON-CREATE": "CREATE",
      "BUTTON-ADD": "ADD",
      "BUTTON-INVITE": "INVITE",
      "BUTTON-UPLOAD": "UPLOAD",
      "BUTTON-INVITE-USER": "INVITE",
      "BUTTON-REMOVE": "REMOVE",
      "BUTTON-UPDATE": "UPDATE",
      "BUTTON-CLAIM": "CLAIM",
      "BUTTON-IMPORT": "IMPORT",
      "BUTTON-ACCEPT": "ACCEPT",
      "BUTTON-GO-BACK": "GO BACK",
      "BUTTON-CANCEL": "CANCEL",
      "BUTTON-CLOSE": "CLOSE",
      "BUTTON-CONTINUE": "CONTINUE",
      "BUTTON-DELETE": "Delete",
      "BUTTON-DECLINE": "DECLINE",
      "BUTTON-ACCEPT-INVITE": "ACCEPT",
      "BUTTON-CLEAR-ALL": "Clear all",
      "BUTTON-GENERATE-TOKEN": "GENERATE TOKEN",
      "BUTTON-EXPORT": "EXPORT",
      "BUTTON-SEARCH-MAP": "Search this area",
      "BUTTON-MORE-FIELDS": "ADD MORE CONTACTS +",
      //**End inputs */

      //**NAV*/
      "NAV-BAR-TITLE-1": "PAGES",
      "NAV-BAR-ITEM-1": "Organizations",
      "NAV-BAR-ITEM-2": "Locations",
      "NAV-BAR-ITEM-3": "Users",
      "NAV-BAR-ITEM-4": "Request",
      "NAV-BAR-TITLE-2": "OTHERS",
      "NAV-BAR-ITEM-5": "Audit Log",
      "NAV-BAR-ITEM-6": "My Organization",
      "NAV-BAR-ITEM-7": "API Tokens",

      "USER-DROPDOWN-OPTION-1": "Profile",
      "USER-DROPDOWN-OPTION-2": "Settings",
      "USER-DROPDOWN-OPTION-3": "Help Center",
      "USER-DROPDOWN-OPTION-4": "Logout",

      //**OTHER PAGES*/
      "NOT-FOUND-TITLE": "Page Not Found ",
      "NOT-FOUND-DESCRIPTION": "We couldn′t find the page you are looking for.",
      "NOT-FOUND-BUTTON": "BACK TO HOME",

      //**LOGIN PUBLIC PAGES*/
      "LOGIN-TITLE": "Welcome to",
      "LOGIN-SUB-TITLE": "Please sign-in to your account",
      "LOGIN-EMAIL": "Email",
      "LOGIN-PASSWORD": "Password",
      "LOGIN-REMEMBER-ME": "Remember me",
      "LOGIN-FORGOT-PASSWORD": "Forgot Password?",
      "LOGIN-BUTTON": "LOGIN",
      "LOGIN-BUTTON-SSO": "SIGN IN WITH SSO",
      "LOGIN-REQUEST-1": "Don’t have an account? ",
      "LOGIN-REQUEST-2": "Request access ",

      "CREATE-PASSWORD-TITLE": "Create Password",
      "RESET-PASSWORD-TITLE": "Reset Password",
      "RESET-PASSWORD-SUBTITLE": "Please enter a new password for your account",

      "FORGOT-PASSWORD-TITLE": "Forgot Password",
      "FORGOT-PASSWORD-SUBTITLE": "Please enter your email and we'll send you instructions to reset your password",

      "VERIFY-ACCOUNT-TITLE": "Verify your email ",
      "VERIFY-ACCOUNT-SUBTITLE1":
        "A link to reset your password has been sent to your email address. Please follow the link inside to continue.",
      "VERIFY-ACCOUNT-SUBTITLE2": "If you didn’t get the email, you can resend it below.",
      "VERIFY-ACCOUNT-TITLE-ERROR": "The link has expired ⚠️",
      "VERIFY-ACCOUNT-SUBTITLE-ERROR": "The link you are trying to access has expired. To resend it please enter your email address below.",

      //** Request User*/
      "STEPPER-1": "Personal Information",
      "STEPPER-2": "Role",
      "STEPPER-3": "SBD POC",
      "STEPPER-4": "Organization",
      "REQUEST-FOOTER-TEST-1": "Already have an account?",
      "REQUEST-FOOTER-TEST-2": "Sign in instead",
      "REQUEST-FOOOTER-BACK-LOGIN": "Go back to Log in",
      "REQUEST-STEP1-TITLE": "Request access to Organization Manager 🔑",
      "REQUEST-STEP1-SUBTITLE": "Manage your organization and the locations that belong to it.",
      "REQUEST-STEP1-SUBTITLE2": "Please enter your data to request access.",
      "REQUEST-TITLE-ORGNAIZATIONS": "Organizations",

      "CARD-REQUEST-TITLE-1": "Multi Organization Manager",
      "CARD-REQUEST-TITLE-2": "Multi Organization Viewer",
      "CARD-REQUEST-TITLE-3": "Organization Manager",
      "CARD-REQUEST-TITLE-4": "Organization Viewer",
      "CARD-REQUEST-TITLE-5": "Location Manager",
      "CARD-REQUEST-TITLE-6": "Location Viewer",
      "CARD-REQUEST-CONTENT-1": "Can manage more than one organization, its locations and users.",
      "CARD-REQUEST-CONTENT-2": "Can only view more than one organization, its locations and users.",
      "CARD-REQUEST-CONTENT-3": "Can manage one organization, its locations and users.",
      "CARD-REQUEST-CONTENT-4": "Can only view one organization, its locations and users.",
      "CARD-REQUEST-CONTENT-5": "Can manage one location inside a specific organization and its users.",
      "CARD-REQUEST-CONTENT-6": "Can only view one location inside a specific organization and its users.",
      "STEP3-INPUT-TITLE": "SBD Point of Contact",
      "STEP3-INPUT-SUBTITLE": "This is the SBD employee who approves your access to the application.",

      "STEP4-INPUT-TITLE": "Organization",
      "STEP4-INPUT-SUBTITLE": "Please enter the name of your organization.",

      "OPTION-SELECT-1": "Suspended",
      "OPTION-SELECT-2": "Active",

      "SELECT-ORGANIZATION-DESCRIPTION": "Please select an organization",
      "SUCCESS-REQUEST-ACCESS-TITLE": "Access Request Submitted ✅",
      "SUCCESS-REQUEST-ACCESS-SUBTITLE": "Your request has been successfully submitted.",
      "SUCCESS-REQUEST-ACCESS-SUBTITLE-2": " You will receive an email notification once it has been reviewed and approved.",

      //**Organizations */
      "TAB-ORGANIZATION-1": "ORGANIZATION DETAILS",
      "TAB-ORGANIZATION-2": "LOCATIONS",
      "TAB-ORGANIZATION-3": "USERS",
      "ORGANIZATION-DETAIL": "Organizations Details",
      "GO-BACK-ORGANIZATION-DASHBOARD": "Go Back to Dashboard",
      "GO-BACK-ORGANIZATION": "Go Back to Organization",
      "GO-BACK-LOCATION": "Go Back to Location",
      "GO-BACK-USER-LIST": "Go Back to Users",
      "GO-BACK-USER-REQUEST": "Go Back to Requests",

      "ORGANIZATION-LIST-NOT-SELECTED": "Select an organization from the list to see quick information",
      "ORGANIZATION-DETAIL-TITLE": "Organization's Form",
      "ORGANIZATION-DETAIL-SUBTITLE": "All organization details can be found here. They can be updated depending on your permissions.",
      "ORGANIZATION-BASIC-INFORMATION": "Basic information",
      "ORGANIZATION-EMAIL-NOTIFICATION-TITLE": "Email notification",
      "ORGANIZATION-EMAIL-NOTIFICATION-SUBTITLE":
        "Notification emails will be sent to all the members of the organization as a reminder to keep the data up to date. The frequency is set monthly by default but can be updated once the organization is already created.",
      "ORGANIZATION-SUSPEND-TITLE": "Suspend organization",
      "ORGANIZATION-SUSPEND-SUBTITLE": "This action is reversible. This is not a deletion.",
      "ORGANIZATION-SWITCH-ACTIVE": "Active",
      "ORGANIZATION-SWITCH-SUSPEND": "Suspended",
      "ORGANIZATION-MODAL-SUSPEND-DESCRIPTION": "The status of the organization will change to suspended.",
      "ORGANIZATION-MODAL-ACTIVE-TITLE": "Activate organization?",
      "ORGANIZATION-MODAL-ACTIVE-DESCRIPTION": "The status of the organization will change to active.",
      "ORG-CREATE-NEW-HQ": "Headquarter Information",
      "ORG-CREATE-NEW-HQ-DESCRIPTION": "The following information is regarding the Principal Location of your organization.",

      //**Locations */
      "LOCATION-CREATE-SUBTITLE": "Fill in all the required fields below to create a new location.",
      "CREATE-LOC-STEPPER-1": "Basic Information",
      "CREATE-LOC-STEPPER-2": "Address",
      "CREATE-LOC-STEPPER-3": "Contact",
      "CREATE-LOC-STEPPER-4": "Business",
      "CREATE-LOC-STEPPER-5": "Additional",
      "LOCATION-LIST-NOT-SELECTED": "Select a Location from the list to see quick information",

      "CHIP-HIDDEN": "Hidden",
      "CHIP-VISIBLE": "Visible",
      "LOCATION-VISIBLITY-SUBTITLE":
        "If visible, your location will be visible in all applications where it is active. If hidden, it will remain hidden in all applications where it was created. This is a reversible action.",
      "STEP-TITLE-CONTACT": "Contact",
      "STEP-TITLE-LOCATION-CONTACT": "Location Contact",
      "STEP-TITLE-OPEN-OPEN-HOURS": "Opening hours",
      "SCHEDULE-DAY-1": "Monday",
      "SCHEDULE-DAY-2": "Tuesday",
      "SCHEDULE-DAY-3": "Wednesday",
      "SCHEDULE-DAY-4": "Thursday",
      "SCHEDULE-DAY-5": "Friday",
      "SCHEDULE-DAY-6": "Saturday",
      "SCHEDULE-DAY-7": "Sunday",
      "OPENING-HOUR-LABEL": "Opening hour",
      "CLOSING-HOUR-LABEL": "Closing hour",
      "TITLE-ADDRESS": "Address",
      "TITLE-LOCATION-ADDRESS": "Location Address",
      "TITLE-ADDITIONAL-FIELDS": "Type of bussiness",
      "ADDITIONAL-FIELDS-DESCRIPTION": "Select the applications that will display this location.",
      "ADDITIONAL-FIELDS-NOT-SELECTED-ALERT": "You must select at least one application to continue.",
      "ADDITIONAL-FIELDS-NOT-PURPOSES-ALERT": "You must select at least one purpose to continue.",

      "LOCATION-TAB-1": "BASIC INFORMATION",
      "LOCATION-TAB-2": "ADDRESS",
      "LOCATION-TAB-3": "CONTACT",
      "LOCATION-TAB-4": "BUSINESS",
      "LOCATION-TAB-5": "BUSINESS",
      "LOCATION-BRANDS": "Brands",
      "LOCATION-KEY-CONTACTS-LANDING": "KEY CONTACT (Internal use only)",
      "LOCATION-KEY-CONTACTS-LANDING-1": "KEY CONTACT ",
      "LOCATION-LOCATION-OWNER": "Primary Contact",
      "LOCATION-SBD-CONECT": "SBD CONNECT",
      "LOCATION-INFO-TITLE": "Location Details",
      "LOCATION-INFO-SUB-TITLE": "All location details can be found here. They can be updated depending on your permissions.",

      "LOCATION-MODAL-VISIBILITY-TITLE": "Hide location?",
      "LOCATION-MODAL-VISIBILITY-DESCRIPTION":
        "The status of the location will change to hidden, meaning that other applications and services might not display this location. This action is reversible.",
      "LOCATION-MODAL-ACTIVE-TITLE": "Make location visible?",
      "LOCATION-MODAL-ACTIVE-DESCRIPTION":
        "The status of the location will change to visible, meaning that other applications and services might display this location.  This action is reversible.",

      "LOCATION-STATUS-TITLE": "Location Status",
      "LOCATION-STATUS-DESCRIPTION": "The status of the location can change between Visible or Hidden. This action is reversible.",
      "LOCATION-MODAL-APPS-ACTIVE-TITLE": "Activate location?",
      "LOCATION-MODAL-APPS-DESACTIVE-TITLE": "Deactivate location?",
      "LOCATION-MODAL-WARRANTY-ACTIVE-DESCRIPTION": "The location will be active in Warranty Claims.",
      "LOCATION-MODAL-WARRANTY-DESACTIVE-DESCRIPTION": "The location will be inactive in Warranty Claims.",
      "LOCATION-MODAL-LOCATION-SERVICES-ACTIVE-DESCRIPTION": "The location will be active in Location Services.",
      "LOCATION-MODAL-LOCATION-SERVICES-DESACTIVE-DESCRIPTION": "The location will be inactive in Location Services.",
      "LOCATION-MODAL-SBDUS-ACTIVE-DESCRIPTION": "The location will be active in SBD Corporate.",
      "LOCATION-MODAL-SBDUS-DESACTIVE-DESCRIPTION": "The location will be inactive in SBD Corporate.",

      "LOCATION-SBDUS-MORE-FIELDS-TITLE-1": "Managing Director/Leader",
      "LOCATION-SBDUS-MORE-FIELDS-TITLE-2": "Key Contact-",
      "LOCATION-SBDUS-MORE-FIELDS-TITLE-3": "Key Contact-02",
      "LOCATION-SBDUS-MORE-FIELDS-TITLE-4": "Key Contact-03",
      "LOCATION-SBDUS-MORE-FIELDS-TITLE-5": "Key Contact-04",
      "LOCATION-FACILITY-INFO": "Facility Information",

      "SUSTAINABLE-TITLE": "Sustainability",
      "SUSTAINABLE-DESCRIPTION": "Indicates that a facility has reduced its overall operational environmental footprint.",
      "LOCATION-ENTER-TO-LOC-DETAILS": " LOCATION DETAILS",
      "LOCATION-HQ-CHANGE-TITLE": "Update your headquarter?",
      "LOCATION-HQ-CHANGE-DESCRIPTION": "Selecting this location will replace the currently assigned headquarter. Do you wish to continue?",
      "LOCATION-HQ-CHANGE-DESCRIPTION-2": "This action will update the hq of your organization. Do you wish to continue?",

      //**Others */
      "GLOBAL-TO": "To",
      //**Users */
      "ROLE-OPTIONS-1": "Super Admin",
      "ROLE-OPTIONS-2": "Organization Owner",
      "ROLE-OPTIONS-3": "Organization Editor",
      "ROLE-OPTIONS-4": "Location Manager",
      "ROLE-OPTIONS-5": "General View Only",
      "ROLE-OPTIONS-6": "Organizaton View Only",
      "INVITATION-VIEW-SUBTITLE": "Fill in all the required fields below to create a new user.",
      "ROLE-RELATION-SUBTITLE": "Please select the relation regarding the role of the new user. ",
      "USERS-INFO-TITLE": "User Details",
      "USERS-PROFILE-TITLE": "Profile",
      "USERS-INFO-SUBTITLE": "All user details can be found here. They can be updated depending on your permissions.",

      "REQUEST-LIST-TITLE": "Pending Access Request",
      "USERS-REQUEST-TITLE": "User Request",
      "USERS-REQUEST-SUBTITLE":
        "All the request details can be found here. You can change the role for the user request, accept or decline the petition.",
      "USER-SUSPEND-TITLE": "Suspend user",
      "USER-SUSPEND-SUBTITLE": "This action is reversible. This is not a deletion.",
      "USER-MODAL-SUSPEND-SUBTITLE":
        "The status of the user will change to suspended, meaning they will not be able to log in to Organization Manager.",
      "USER-MODAL-ACTIVE-TITLE": "Activate user?",
      "USER-MODAL-ACTIVE-DESCRIPTION":
        "The status of the user will change to active, meaning they will be able to log in to Organization Manager.",
      "ADD-USERS-MODAL-TITLE": "ADD A USER",
      // Requests
      "REQUEST-MODAL-DECLINE-TITLE": "Decline request?",
      "REQUEST-MODAL-DECLINE-DESCRIPTION": "The user will not be allowed to access Organization Manager.",

      "REQUEST-TABLE-HEADER-1": "Date",
      "REQUEST-TABLE-HEADER-2": "Full Name",
      "REQUEST-TABLE-HEADER-3": "Email",
      "REQUEST-TABLE-HEADER-4": "SBD POC",
      "REQUEST-TABLE-HEADER-5": "Role",
      "REQUEST-BASIC-INFORMATION": "User Basic Information",

      "ACTIVITY-TYPE-1": "Create",
      "ACTIVITY-TYPE-2": "Update",
      "ACTIVITY-TYPE-3": "Invite",
      "ACTIVITY-TYPE-4": "Delete",
      "ACTIVITY-TYPE-5": "Import",
      "ACTIVITY-TYPE-6": "Pending",
      "ACTIVITY-TYPE-7": "Approved",
      "ACTIVITY-TYPE-8": "Denied",

      "DATE-OPTION-1": "Any time",
      "DATE-OPTION-2": "Today",
      "DATE-OPTION-3": "Yesterday",
      "DATE-OPTION-4": "Last 7 days",
      "DATE-OPTION-5": "Last 30 days",
      "DATE-OPTION-6": "Last 90 days",
      "DATE-OPTION-7": "Custom...",

      //** Audit log */
      "AUDIT-LOG-SUB-TITLE": "All the details of this activity can be found here.",
      "AUDIT-TABLE-HEADER-1": "Time",
      "AUDIT-TABLE-HEADER-2": "Actor",
      "AUDIT-TABLE-HEADER-3": "Resource",
      "AUDIT-TABLE-HEADER-4": "Activity Type",
      "GO-BACK-AUDIT-LOG": "Go Back to Audit Log",
      "IMPORT-ERROR-LIST-TITLE": "Error List",

      //** API Token */
      "API-TOKEN-SUB-TITLE": "Create a token to use the Organization Manager API.",
      "API-NEW-TOKEN-TITLE": "New Token",
      "API-NEW-TOKEN-ALERT": "Make sure to copy and store this token. We won’t show it again after you click continue. ",
      "API-DELETE-TOKEN-TITLE": "Delete token?",
      "API-DELETE-TOKEN-DESCRIPTION": "Once removed, any calls to the API that use that token will no longer work.",
      "NAME-INPUT-TOKEN-HELPER": "The name of the API token must be unique.",
      "DESCRIPTION-INPUT-TOKEN-HELPER": "Describe the applications or services that will use this token..",

      /* Icons Header ToolTip */
      "ICON-SUN-TOOLTIP": "Light mode",
      "ICON-MOON-TOOLTIP": "Dark Mode",
      "ICON-NOTIFICATION-TOOLTIP": "Notifications",
      "ICON-ORGANIZATIONS-TOOLTIP": "Organizations",
      "ICON-APPS-TOOLTIP": "Apps",
      "ICON-ORGANIZATIONS-INFO": "View details",
      "ICON-ORGANIZATIONS-MORE-ACTIONS": "More actions",
      "ICON-NOTIFICACION": "Notifications",
      "ICON-DELETE": "Delete",
      "ICON-EXPORT": "Export",
      "ICON-CLAIM-SEARCH-TOOLTIP": "More filters",
      "TOOLTIP-RESET-DATA": "Reset value",

      //** Empty cards */
      "EMPTY-CARD-1-TITLE": "Create",
      "EMPTY-CARD-2-TITLE": "Claim",
      "EMPTY-CARD-3-TITLE": "Import",
      "EMPTY-CARD-4-TITLE": "Invite",
      "EMPTY-CARD-1-DESCRIPTION": "Create a location from scratch by filling in the form.",
      "EMPTY-CARD-2-DESCRIPTION": "Search and claim locations using Google Maps.",
      "EMPTY-CARD-3-DESCRIPTION": "Import locations using a csv file.",
      "EMPTY-CARD-3-1-DESCRIPTION": "Import user using a csv file.",
      "EMPTY-CARD-4-DESCRIPTION": "Invite a user by filling in the form.",
      "EMPTY-CARD-5-DESCRIPTION": "Create a organization from scratch by filling in the form.",
      "EMPTY-LOCATIONS-TITLE": "No locations yet",
      "EMPTY-USERS-TITLE": "No users yet",
      "EMPTY-FILTER-USERS-TITLE": "No results found",
      "EMPTY-FILTER-USERS-DESCRIPTION": "There are no results for this search. Try adjusting your search filters.",
      "EMPTY-LOCATIONS-DESCRIPTION": "This organization doesn’t have locations yet. You can create, claim or import them.",
      "EMPTY-USERS-DESCRIPTION": "This organization doesn’t have users yet. You can invite or import them.",
      "EMPTY-FILTER-LOCATIONS-TITLE": "No results found",
      "EMPTY-FILTER-LOCATIONS-DESCRIPTION": "There are no results for this search. Try adjusting your search filters.",
      "EMPTY-FILTER-TITLE": "No results found",
      "EMPTY-FILTER-DESCRIPTION": "There are no results for this search. Try adjusting your search filters.",
      "EMPTY-API-TOKEN": "No API tokens yet",
      "EMPTY-API-TOKEN-DESCRIPTION": "There are no API tokens created yet. You can generate one by clicking on the 'Create' button.",
      "EMPTY-AUDIT-LOG": "No audit logs yet",
      "EMPTY-AUDIT-LOG-DESCRIPTION": "There are no audit logs yet",

      "LOCATION-CLAIMS-ACTION": "Claim locations",
      "CALIM-LOCATION-MODAL-TITLE": "Claim locations?",
      "CALIM-LOCATION-MODAL-DESCRIPTION":
        "The locations will be created with a hidden status. To make them visible please complete all the information.",

      //** Bulk */ */
      "BULK-FREC-MENU": "Notification frequency",
      "BULK-FREC-SUB-MENU-1": "Weekly",
      "BULK-FREC-SUB-MENU-2": "Monthly",
      "BULK-CHANGE-ORG": "Change Organization",
      "MENU-EXPORT": "Export",

      "BULK-STATUS-ORG-POPUP.TITLE": "Do you want to suspend this Organization?",
      "BULK-STATUS-ORG-POPUP.TITLE-2": "Do you want to activate this Organization?",
      "BULK-STATUS-ORG-POPUP": "The status of the organizations will change to active.",
      "BULK-STATUS-2-ORG-POPUP":
        "The status of the organization will change to Suspended. The locations and users inside will no longer be available.",
      "BULK-FREC-ORG-POPUP": "The notification frequency of the organizations will change to weekly.",
      "BULK-FREC-2-ORG-POPUP": "The notification frequency of the organizations will change to monthly.",
      "CLAIM-LOCATION-DESCRIPTION": "This is a list of potential locations that may belong to your organization and can be claimed.",

      "BULK-LOC-VISIBLE": "The status of the locations will change to visible",
      "BULK-LOC-HIDE": "The status of the locations will change to hidden",
      "BULK-ORG-INFO": "The organization of the locations will change to",
      "BULK-ORG-DESCRIPTION": "The organization of the locations will change",

      "BULK-USER-ACTIVATE": "The status of the users will change to active, meaning they will be able to log in to Organization Manager.",
      "BULK-USER-SUSPEND":
        "The status of the users will change to suspend, meaning they will not be able to log in to Organization Manager.",
      //**Import */
      "IMPORT-HELP-1": "* Existing records will be overwritten.",
      "IMPORT-HELP-2": "* Please leave the 'Location ID' column blank for new locations and don't modify it for existing ones.",
      "IMPORT-HELP-3": "* Maximum amount of records to be imported per file: 1000",
      "LOC-IMPORT-NOTIFICATION-TITLE": "The import process has started!",
      "LOC-IMPORT-NOTIFICATION-DESC-1": "We'll let you know when it's complete.",
      "LOC-IMPORT-NOTIFICATION-DESC-2": "Please don't refresh or close the application.",
      "LOC-IMPORT-NOTIFICATION-EXPORT-TITLE": "The export process has started!",

      "LOC-EXPORT-TITLE": "Export locations to a CSV file.",
      "LOC-EXPORT-ITEM-1": "Current page",
      "LOC-EXPORT-ITEM-2": "All locations",
      "LOC-EXPORT-ITEM-3": "Selected locations",
      "LOC-EXPORT-ITEM-4": "Current search",

      "USER-EXPORT-TITLE": "Export users to a CSV file.",
      "USER-EXPORT-ITEM-1": "Current page",
      "USER-EXPORT-ITEM-2": "All users",
      "USER-EXPORT-ITEM-3": "Selected users",
      "USER-EXPORT-ITEM-4": "Current search",

      "IMPORT-DETAIL-TOTAL": "Total",
      "IMPORT-DETAIL-SUCCESS": "Created/updated",
      "IMPORT-DETAIL-WARNING": "With inconsistencies",
      "IMPORT-DETAIL-ERROR": "Failed",

      "TOOLTIP-ACTIVE-INFO": "Location displayed in this application.",
      "TOOLTIP-HIDDEN-INFO": "Location hidden in this application.",
      "USERS-DELETE-MODAL-CONFIRM-DESCRIPTION": "The User will be deleted from the list. This action is irreversible",
      "LOCATIONS-DELETE-MODAL-CONFIRM-DESCRIPTION": "will be deleted from the list. This action is irreversible.",
      "LOCATIONS-DELETE-MODAL-ERROR-HQ": "You cannot delete a HQ location",
      "LOCATIONS-CHANGE-ORG-MODAL-ERROR-HQ": "You cannot change a HQ organization",
      "ORGANIZATIONS-DELETE-MODAL-CONFIRM-DESCRIPTION":
        "The organization will be deleted, meaning that their locations will also be deleted. This action is irreversible",
      "USERS-DELETE-MODAL-CONFIRM-TITLE": "Delete user",
      "USERS-MODAL-CONFIRM-TITLE": "Update user?",
      "USERS-MODAL-CONFIRM-DESCRIPTION": "Are you sure to update your infomation?",
      "LOCATIONS-DELETE-MODAL-CONFIRM-TITLE": "Delete location",
      "ORGANIZATIONS-DELETE-MODAL-CONFIRM-TITLE": "Delete organization",
    },
  },
  es: {
    translation: {
      "USER-DROPDOWN-OPTION-1": "Perfil",
    },
  },
};
export default resources;
